import { useMutation } from 'react-query';
import { import2Api } from '@services/api/import2Api';
import { errorHandler } from '@services/api/helpers';

export const useInitImport2 = () =>
  useMutation<string, Error, { toolName: string }>(async (dto) => {
    try {
      return (await import2Api.initImport(dto)).data;
    } catch (e) {
      throw errorHandler(e);
    }
  });
