import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { ChevronDown, DownloadCloud } from 'react-feather';
import { FileDownIcon } from '@kit/ui/icons/FileDownIcon';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useImport2Tools, useInitImport2 } from '@hooks/import2';
import { Select } from '@kit/ui/Select';
import { useMakeFlatfileSpace } from '@hooks/workspace/connectIntegration';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { HubSpotIcon } from '@kit/ui/icons/corporate/HubSpot';
import { QuickbooksIcon } from '@kit/ui/icons/corporate/Quickbooks';
import { ZendeskIcon } from '@kit/ui/icons/corporate/Zendesk';
import { PipedriveIcon } from '@kit/ui/icons/corporate/Pipedrive';
import { SalesforceIcon } from '@kit/ui/icons/corporate/Salesforce';
import { Popover } from '@kit/ui/Popover';
import { AllCRMLabel, CardSubtitle, CardTitle, Container, Header, ImportCard } from './styled';
import { History } from './History';

export const Import = () => {
  const { mutateAsync: makeFlatfileSpace, isLoading: flatfileSpaceLoading } = useMakeFlatfileSpace();
  const companySettings = useAppSelector(selectCompanySettings);

  const isRequestsEnabled = companySettings?.features?.requests;

  const handleOpenTool = async () => {
    const redirectWindow = window.open('about:blank', '_blank');

    redirectWindow.location.href = await makeFlatfileSpace();
  };

  const { data: import2Tools, isLoading: isImport2ToolsLoading, isError: isImport2ToolsError } = useImport2Tools();

  const hubspotTool = useMemo(() => import2Tools.find((tool) => tool.name === 'hubspot'), [import2Tools]);
  const quickbooksTool = useMemo(() => import2Tools.find((tool) => tool.name === 'quickbooks'), [import2Tools]);
  const zendeskTool = useMemo(() => import2Tools.find((tool) => tool.name === 'zendesksell'), [import2Tools]);
  const pipedriveTool = useMemo(() => import2Tools.find((tool) => tool.name === 'pipedrive'), [import2Tools]);
  const salesforceTool = useMemo(() => import2Tools.find((tool) => tool.name === 'salesforce'), [import2Tools]);

  const { mutateAsync: initImport2 } = useInitImport2();

  const openImport2Importer = async (toolName: string) => {
    const redirectWindow = window.open('about:blank', '_blank');

    redirectWindow.location.href = await initImport2({ toolName });
  };

  return (
    <Container>
      <Header>
        <h1>Import</h1>
        <div>Add or update {isRequestsEnabled ? 'Requests, ' : ''}Projects and Clients</div>
      </Header>

      <Box display="flex" gridGap={24}>
        <ImportCard>
          <Box display="flex" flexDirection="column" gridGap={8} alignItems="center">
            <CardTitle>
              Import from another software <DownloadCloud size={24} />
            </CardTitle>
            <CardSubtitle>Import data from another CRM software through our third-party partner, Import2.</CardSubtitle>
          </Box>

          <Box display="flex" flexDirection="column" gridGap={8} alignItems="center">
            <Box display="flex" gridGap={8}>
              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openImport2Importer(hubspotTool?.name)}
                disabled={!hubspotTool}
              >
                <HubSpotIcon size={24} />
                HubSpot
              </Button>

              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openImport2Importer(quickbooksTool?.name)}
                disabled={!quickbooksTool}
              >
                <QuickbooksIcon size={24} />
                Quickbooks
              </Button>

              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openImport2Importer(zendeskTool?.name)}
                disabled={!zendeskTool}
              >
                <ZendeskIcon size={24} />
                Zendesk
              </Button>

              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openImport2Importer(pipedriveTool?.name)}
                disabled={!pipedriveTool}
              >
                <PipedriveIcon size={24} />
                Pipedrive
              </Button>

              <Button
                variant={ButtonVariant.Outlined}
                onClick={() => openImport2Importer(salesforceTool?.name)}
                disabled={!salesforceTool}
              >
                <SalesforceIcon size={24} />
                Salesforce
              </Button>
            </Box>
          </Box>

          <Popover
            content={
              <Select
                options={import2Tools}
                onChange={(_, tool) => (tool ? openImport2Importer(tool.name) : null)}
                getOptionLabel={(tool) => tool.label}
                style={{ minWidth: 332 }}
                placeholder="Search..."
                disabled={isImport2ToolsLoading || isImport2ToolsError}
              />
            }
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
          >
            <AllCRMLabel>
              All CRM <ChevronDown size={16} />
            </AllCRMLabel>
          </Popover>
        </ImportCard>

        <ImportCard>
          <CardTitle>
            Import from CSV <FileDownIcon size={24} />
          </CardTitle>
          <Button variant={ButtonVariant.Primary} onClick={handleOpenTool} disabled={flatfileSpaceLoading}>
            {flatfileSpaceLoading ? 'Loading...' : 'Open import tool'}
          </Button>
        </ImportCard>
      </Box>

      <History />
    </Container>
  );
};
