import { useAppSelector } from '@hooks/index';
import { selectCompanySettings } from '@state/selectors';
import React from 'react';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { useUpdateEffect } from '@react-hookz/web';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { SettingsBlock } from '../SettingsBlock';
import { Container, Separator } from './styled';

export const Configurations = () => {
  const companySettings = useAppSelector(selectCompanySettings) ?? {};
  const { requestButtonEnabled = false, pastDueUncompletedEventsVisible = false } = companySettings;

  const isRequestsEnabled = companySettings?.features?.requests;

  const { mutateAsync: updateCompany } = useUpdateCompany();

  const [isRequestButtonEnabled, isRequestButtonEnabledDebounced, setIsRequestButtonEnabled] = useDebouncedState(
    requestButtonEnabled,
    300
  );
  const [
    isPastDueUncompletedEventsVisible,
    isPastDueUncompletedEventsVisibleDebounced,
    setIsPastDueUncompletedEventsVisible
  ] = useDebouncedState(pastDueUncompletedEventsVisible, 300);

  useUpdateEffect(() => {
    updateCompany({
      settings: {
        requestButtonEnabled: isRequestButtonEnabledDebounced
      }
    });
  }, [isRequestButtonEnabledDebounced]);

  useUpdateEffect(() => {
    updateCompany({
      settings: {
        pastDueUncompletedEventsVisible: isPastDueUncompletedEventsVisibleDebounced
      }
    });
  }, [isPastDueUncompletedEventsVisibleDebounced]);

  return (
    <Container>
      {isRequestsEnabled && (
        <>
          <SettingsBlock
            title="Request button"
            description="The Request Button in the Client Portal enables clients to submit specific requests directly and efficiently."
            isEnabled={isRequestButtonEnabled}
            onToggleEnabled={setIsRequestButtonEnabled}
          />

          <Separator />
        </>
      )}

      <SettingsBlock
        title={
          isRequestsEnabled ? 'Show past due uncompleted Visits and Appointments' : 'Show past due uncompleted Visits'
        }
        description={
          isRequestsEnabled
            ? 'Display past due uncompleted Visits in Projects and Appointments in Requests in Client Portal.'
            : 'Display past due uncompleted Visits in Projects in Client Portal.'
        }
        isEnabled={isPastDueUncompletedEventsVisible}
        onToggleEnabled={setIsPastDueUncompletedEventsVisible}
      />
    </Container>
  );
};
