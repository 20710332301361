import { SystemEnergy } from '@services/api/fleetChartsAPI';
import { DateTime } from 'luxon';
import { useSystemsLastWeekProductionCharts } from '@hooks/systems/useSystemsLastWeekProductionCharts';
import { useMemo } from 'react';

export const transformChartData = (data: SystemEnergy[]) => {
  if (!data || data.length === 0) {
    return {};
  }

  return data
    .filter((value) => value)
    .reduce((acc: Record<string, { total: number; points: { time: DateTime; value: number }[] }>, systemEnergy) => {
      const { system, time, value } = systemEnergy || {};

      if (!acc[system]) {
        acc[system] = { total: 0, points: [] };
      }
      acc[system].points.push({ time: DateTime.fromSQL(time).toLocal(), value });
      acc[system].total += value;

      return acc;
    }, {});
};

export const useFlattenSystemsProductionCharts = (systemsIds: number[], perPage: number = 10) => {
  const { data, fetchNextPage, isFetching, hasNextPage, fetchPreviousPage } = useSystemsLastWeekProductionCharts(
    systemsIds,
    perPage
  );

  const systemEnergyData = useMemo(() => {
    return data?.pages.flatMap((page) => page.data as unknown as SystemEnergy[][]).flat();
  }, [data]);
  const transformedData = useMemo(
    () => (systemEnergyData ? transformChartData(systemEnergyData) : {}),
    [systemEnergyData]
  );

  return {
    data: transformedData,
    totalPages: data?.pages?.length || 0,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    hasNextPage
  };
};
