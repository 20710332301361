import React, { useCallback, useEffect, useMemo } from 'react';
import Loader from '@common/Loader';
import { useSelectionWithGroups } from '@features/SystemPortfolio/List/Table/useSelection';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { COLUMNS, renderColumnPlain } from '@features/SystemPortfolio/constants';
import { SystemColumn, SystemWithStats, useSystems } from '@hooks/systems';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { SystemStatus } from '@features/SystemPortfolio/components';
import { useShowMap } from '@features/SystemPortfolio/List/Table/useShowMap';
import { columnStyleWithMap, columnStyleWithoutMap } from '@features/SystemPortfolio/List/Table/styled';
import { SystemEnergyAverageChart } from '@features/SystemPortfolio/components/SystemEnergyAverageChart';
import { useFlattenSystemsProductionCharts } from '@hooks/systems/useFlattenSystemsWeekProductionCharts';
import { mapClientFiltersToServerFilters, useClientFilterState } from '../../useClientFilterState';
import { TitleCell } from './TitleCell';
import { Container, LoaderWrapper, LoadMore, Row } from './styled';

interface Props {
  ids: number[];
  firstPageData: SystemWithStats[];
  groupLabel: string | null;
}

export const TableBody = ({ ids = [], firstPageData = [], groupLabel }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    clientFilters: { perPage, query, monitored, linking, status, source, fleet, alerts }
  } = useClientFilterState();
  const {
    data: systemsProductionChartById,
    isFetching: isFetchingChart,
    fetchNextPage: fetchNextChartPage
  } = useFlattenSystemsProductionCharts(ids, perPage.value);

  const { condition, filter } = mapClientFiltersToServerFilters({
    companyId,
    status,
    linking,
    source,
    fleet,
    alerts,
    query,
    monitored
  });

  const {
    data: systems,
    isFetching: isFetchingNextPage,
    fetchNextPage
  } = useSystems(
    {
      condition,
      filter: {
        ...filter,
        id: { in: ids },
        integration: { companyId: { equalTo: companyId } }
      },
      first: perPage.value
    },
    {
      enabled: ids.length > 0
    },
    firstPageData
  );

  const { setDataForGroup, isItemSelected } = useSelectionWithGroups();
  useEffect(() => setDataForGroup(groupLabel, systems), [systems, groupLabel, setDataForGroup]);

  const { openDrawer } = useDrawersContext();
  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.SYSTEM, id, ids);
    },
    [openDrawer, ids]
  );

  const [showMap] = useShowMap();
  const columnStyle = useMemo(() => (showMap ? columnStyleWithMap : columnStyleWithoutMap), [showMap]);

  return (
    <Container>
      <tbody>
        {systems.map((system) => (
          <Row
            key={system.id}
            isSelected={isItemSelected(groupLabel, system)}
            isDimmed={!system.monitored}
            onClick={() => handleRowClick(system.id)}
          >
            {COLUMNS.map((column) => {
              switch (column.id) {
                case SystemColumn.name:
                  return (
                    <TitleCell key={column.id} system={system} groupLabel={groupLabel} style={columnStyle[column.id]} />
                  );

                case SystemColumn.status:
                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <SystemStatus system={system} vertical />
                    </td>
                  );

                case SystemColumn.sevenDayAverage: {
                  const chartData = systemsProductionChartById[system.uuid];

                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <SystemEnergyAverageChart monitored={system.monitored} points={chartData?.points ?? []} />
                    </td>
                  );
                }

                default:
                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <span>{renderColumnPlain(system, column.id)}</span>
                    </td>
                  );
              }
            })}
          </Row>
        ))}
      </tbody>

      {ids.length > systems.length && (
        <tfoot>
          <tr>
            <td>
              {(isFetchingNextPage || isFetchingChart) && (
                <LoaderWrapper>
                  <Loader size={24} />
                </LoaderWrapper>
              )}

              {!isFetchingNextPage && !isFetchingChart && (
                <LoadMore
                  onClick={() => {
                    fetchNextPage();
                    fetchNextChartPage();
                  }}
                >
                  Load more ({ids.length - systems.length})
                </LoadMore>
              )}
            </td>
            <td colSpan={COLUMNS.length - 1} />
          </tr>
        </tfoot>
      )}
    </Container>
  );
};
