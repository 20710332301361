import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { import2Api } from '@services/api/import2Api';
import { apiErrorHandler } from '@utils';

export const useImport2Tools = () =>
  useQuery<{ name: string; label: string }[]>({
    queryKey: [ReactQueryKey.Import2],
    queryFn: async () => {
      try {
        return (await import2Api.getTools()).data;
      } catch (e) {
        throw apiErrorHandler('Error fetching Import2 tools', e);
      }
    },
    initialData: []
  });
