import React from 'react';
import { Select } from '@kit/ui/Select';
import { PER_PAGE_OPTIONS } from '@features/ClientPortfolio/constants';
import { PerPage, SelectionAndPerPage, Table } from './styled';
import { useClientFilterState } from '../../useClientFilterState';

const COLUMNS: { id: string; name: string }[] = [
  { id: 'name', name: 'Name' },
  { id: 'email', name: 'Email' },
  { id: 'phone', name: 'Phone' },
  { id: 'createdAt', name: 'Created' }
];

export const TableHeader = () => {
  const { clientFilters, updateFilters } = useClientFilterState();

  return (
    <>
      <SelectionAndPerPage>
        <PerPage>
          <div>Show</div>
          <Select
            options={PER_PAGE_OPTIONS}
            value={clientFilters.perPage}
            onChange={(_, value) => updateFilters({ perPage: value })}
            isClearable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <div>Contacts per page</div>
        </PerPage>
      </SelectionAndPerPage>
      <Table>
        <thead>
          <tr>
            {COLUMNS.map((column) => (
              <th key={column.id}>{column.name}</th>
            ))}
          </tr>
        </thead>
      </Table>
    </>
  );
};
