import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { PrivilegedTask, TaskStatus, TaskStatusUpdate } from '@generated/types/graphql';
import { colors, WHISKERS_COLORS } from '@styles';
import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ViewType } from 'devextreme/ui/scheduler';
import { useDraggedEvent } from '@components/Scheduler/Dispatcher/Resources/useDraggedEvent';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { DateTime } from 'luxon';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { useTooltip } from '@kit/ui/Floats';
import { FloatingPortal } from '@floating-ui/react';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  Header,
  ReminderContainer,
  Text,
  TimelineWhiskers,
  Title,
  WhiskerTooltip,
  WorkOrderStatus
} from './styled';
import { formatWorkOrderEventDateTime } from '../helpers';

const getOpacity = ({ isFilteredOut, isCollaborator }: any) => {
  if (isFilteredOut) {
    return 0.3;
  }

  if (isCollaborator) {
    return 0.6;
  }

  return 1;
};

const whiskersStatuses: TaskStatus[] = [TaskStatus.OnTheWay, TaskStatus.ClockedIn, TaskStatus.ClockedOut];

const inProgressStatuses: TaskStatus[] = [TaskStatus.OnTheWay, TaskStatus.ClockedIn];

type Props = {
  data: { appointmentData: PrivilegedTask };
  view: ViewType;
};

export const WeekEvent = ({ data, view }: Props) => {
  const settings = useAppSelector(selectCompanySettings);

  const order = data.appointmentData;

  const ReminderIc = REMINDER_TYPE_TO_ICON[order.type];

  const ref = useRef<HTMLElement>(null);

  const [timelineWhiskers, setTimelineWhiskers] = useState<ReactNode[]>([]);

  const [hoveredWhisker, setHoveredWhisker] = useState<TaskStatusUpdate>(null);

  const whiskersTooltip = useTooltip({
    placement: 'top',
    open: !!hoveredWhisker
  });

  const { data: taskStatuses = [] } = useWorkOrderStatuses();

  useEffect(() => {
    if (!settings?.features?.geolocation || !ref.current || view !== 'day' || !order.startDate || !order.endDate) {
      return;
    }

    const { width } = ref.current.getBoundingClientRect();
    const taskStart = new Date(order.startDate).getTime();
    const taskEnd = new Date(order.endDate).getTime();
    const millisPerPixel = (taskEnd - taskStart) / width;

    setTimelineWhiskers(
      (order.taskStatusUpdatesByTaskId || []).reduce((acc, update, index, updates) => {
        if (whiskersStatuses.includes(update.status)) {
          const statusStart = DateTime.fromISO(update.createdAt, { zone: 'utc' }).toMillis();
          const statusEnd =
            index < updates.length - 1
              ? DateTime.fromISO(updates[index + 1].createdAt, { zone: 'utc' }).toMillis()
              : Date.now();

          const left = (statusStart - taskStart) / millisPerPixel;
          const right = (statusEnd - taskStart) / millisPerPixel;

          const isDashed = update.status === TaskStatus.OnTheWay ? '5 3' : 'none';
          const hasLine = inProgressStatuses.includes(update.status);

          acc.push(
            <g>
              {hasLine && (
                <line
                  stroke={WHISKERS_COLORS[update.status].line}
                  strokeDasharray={isDashed}
                  x1={left + 4}
                  x2={right - 4}
                  y1={4}
                  y2={4}
                />
              )}
              <rect
                fill={WHISKERS_COLORS[update.status].fill}
                stroke={WHISKERS_COLORS[update.status].stroke}
                width={8}
                height={8}
                x={left - 4}
                y={0}
                rx={1}
                ry={1}
                onMouseEnter={(e) => {
                  setHoveredWhisker(update);
                  whiskersTooltip.refs.setReference(e.target as SVGElement);
                }}
                onMouseLeave={() => setHoveredWhisker(null)}
              />
            </g>
          );
        }

        return acc;
      }, [])
    );
  }, [settings, ref, order, view, taskStatuses, whiskersTooltip]);

  const [draggedEvent] = useDraggedEvent();

  if ('dueDate' in order) {
    const { isAppointment } = order;

    return (
      <ReminderContainer opacity={getOpacity(order)}>
        <Header>
          {isAppointment && <CalendarIcon color={colors.green} size="12px" />}
          {!isAppointment && Boolean(ReminderIc) && <ReminderIc color={colors.green} size="12px" />}
          <Text isCompleted={order.isCompleted}>{order.title}</Text>
        </Header>
        <Text>{[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}</Text>
      </ReminderContainer>
    );
  }

  return (
    <Tooltip
      title={
        <div>
          {order.title}
          <div>{order.project?.address[0]}</div>
          <div>{formatWorkOrderEventDateTime(order)}</div>
        </div>
      }
    >
      <Container
        ref={ref}
        opacity={getOpacity(order)}
        labelColor={order.firstLabel?.color}
        labelFill={order.isColored ? order.firstLabel?.color : null}
      >
        <div>
          <Title isCompleted={order.isCompleted}>
            {order.isCollaborator && order.assignee && <UserAvatar user={order.assignee} size={16} />}
            <WorkOrderTypeIcon isField={order.isField} />
            <Text isBold>{order.title}</Text>
          </Title>

          <Text>{[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}</Text>
        </div>
        <div>
          <WorkOrderStatus status={order.taskStatus} />
        </div>
        {order.id !== draggedEvent?.id && (
          <>
            <TimelineWhiskers>{timelineWhiskers}</TimelineWhiskers>

            {hoveredWhisker && (
              <FloatingPortal>
                <WhiskerTooltip ref={whiskersTooltip.refs.setFloating} style={whiskersTooltip.floatingStyles}>
                  <div>
                    {order.uid ? `#${order.uid}` : order.title} ·{' '}
                    {taskStatuses.find((status) => status.id === hoveredWhisker.status)?.label}
                  </div>
                  <div>
                    {DateTime.fromISO(hoveredWhisker.createdAt, { zone: 'utc' })
                      .toLocal()
                      .toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                </WhiskerTooltip>
              </FloatingPortal>
            )}
          </>
        )}
      </Container>
    </Tooltip>
  );
};
