import React, { useCallback } from 'react';
import { formatWorkOrderEventDateTime } from '@components/Scheduler/Dispatcher/Resources/helpers';
import { Container, LabelCircle, Title } from './styled';

export const EventListTooltip = ({ data, onClick }) => {
  const order = data.appointmentData;

  const handleClick = useCallback(() => {
    onClick?.(order);
  }, [order, onClick]);

  return (
    <Container onClick={handleClick} color={order.isColored ? order.firstLabel?.color : null}>
      {!order.isColored && <LabelCircle color={order.firstLabel?.color} />}
      <Title isCompleted={order.isCompleted}>{order.title}</Title>
      <div>
        {formatWorkOrderEventDateTime(order)} ·{' '}
        {[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}
      </div>
    </Container>
  );
};
