import styled from 'styled-components';
import { colors, fonts } from '@styles';

export const Container = styled.div`
  padding: 24px 16px;

  height: calc(100vh - 48px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
  }
`;

export const Header = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
  }

  font-size: 14px;
`;

export const ImportCard = styled.div`
  padding: 24px;
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  background-color: ${colors.white};
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;

export const CardTitle = styled.div`
  font: 500 18px/1.25 ${fonts.primary};
  color: #1d1d35;
  display: flex;
  align-content: center;
  gap: 8px;
`;

export const CardSubtitle = styled.div`
  font: 400 12px/130% ${fonts.primary};
  color: #797786;
  text-align: center;
`;

export const Import2Logo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  padding: 4px 8px;
  font: 500 12px/130% ${fonts.primary};
`;

export const AllCRMLabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font: 500 12px/130% ${fonts.primary};
  color: ${colors.green};
`;
