import { useInfiniteQuery } from 'react-query';
import { apiErrorHandler } from '@utils';
import fleetChartsAPI, { ChartDTO, ChartResult, Meter } from '@services/api/fleetChartsAPI';
import { ChartMilestone, chartMilestoneConfig, chartPeriodConfig } from '@hooks/systems/constants';

const fetchProductionCharts = async (
  idsToFetch: number[],
  pageParam: { start: number; end: number },
  chartsDto: ChartDTO[]
): Promise<{ data: ChartResult[]; nextPage: number | null }> => {
  try {
    const { data } = await fleetChartsAPI.getSystemsCharts(idsToFetch, chartsDto);

    return { data, nextPage: idsToFetch.length > 0 ? pageParam.end : null };
  } catch (e) {
    throw apiErrorHandler('Error fetching systems production', e);
  }
};

export const useSystemsLastWeekProductionCharts = (systemsIds: number[], perPage: number = 10) => {
  const milestoneConfig = chartMilestoneConfig[ChartMilestone.week];
  const { period } = milestoneConfig;
  const statsInterval = milestoneConfig.interval();
  const startAt = statsInterval?.start;
  const endBefore = statsInterval?.end;
  const periodConfig = chartPeriodConfig[period];
  const chartsDto: ChartDTO[] = [
    {
      metric: 'energy',
      kind: 'points',
      meter: Meter.Production,
      period: periodConfig.pointGranularity.toISO(),
      startAt: startAt?.toISO(),
      endBefore: endBefore?.toISO()
    }
  ];

  return useInfiniteQuery(
    ['useSystemsProductionCharts', chartsDto],
    async ({ pageParam = { start: 0, end: perPage } }) => {
      const idsToFetch = systemsIds.slice(pageParam.start, pageParam.end);

      const { data, nextPage } = await fetchProductionCharts(idsToFetch, pageParam, chartsDto);

      return {
        data,
        nextPage
      };
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.nextPage) {
          return null;
        }

        const start = lastPage.nextPage;
        const end = start + perPage;

        return { start, end };
      },
      keepPreviousData: true,
      staleTime: 1000 * 60
    }
  );
};
