import React, { useCallback, useMemo, useState } from 'react';

import { TabItem, Tabs } from '@kit/ui/Tabs';
import { SearchBar } from '@common/SearchBar';
import { useAutomationList } from '@hooks/automations/useAutomationList';
import { Trigger, AutomationStepType } from '@enums';
import { useNavigate } from '@reach/router';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { RecordType } from '@types';
import { selectCompanySettings } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { AutomationRow } from './AutomationRow';
import { Table, Container, Header, HeaderLeft, TableContainer, PageHeader, Title } from './styled';

enum AutomationSubject {
  Client = 'client',
  Project = 'project',
  WorkOrder = 'workOrder',
  Request = 'request'
}

type AutomationTab = TabItem & {
  triggers?: Trigger[];
  recordType?: RecordType;
};

const TABS: AutomationTab[] = [
  {
    id: 'all',
    title: 'All'
  },
  {
    id: AutomationSubject.Client,
    title: 'Clients',
    triggers: [Trigger.CALL_UPSERTED],
    recordType: RecordType.ACCOUNT
  },
  {
    id: AutomationSubject.Request,
    title: 'Requests',
    triggers: [
      Trigger.CALL_UPSERTED,
      Trigger.APPOINTMENT_CREATED,
      Trigger.APPOINTMENT_RESCHEDULED,
      Trigger.DEAL_PROPERTY_UPDATED,
      Trigger.DEAL_MOVEMENT,
      Trigger.DEAL_SLA_VIOLATION,
      Trigger.REQUEST_CREATED,
      Trigger.DEAL_STATUS_MOVEMENT
    ],
    recordType: RecordType.DEAL
  },
  {
    id: AutomationSubject.Project,
    title: 'Projects',
    triggers: [
      Trigger.CALL_UPSERTED,
      Trigger.PROJECT_MOVEMENT,
      Trigger.PROJECT_PROPERTY_UPDATED,
      Trigger.PROJECT_SLA_VIOLATION,
      Trigger.PROJECT_CREATED
    ],
    recordType: RecordType.PROJECT
  },
  {
    id: AutomationSubject.WorkOrder,
    title: 'Work Orders',
    triggers: [Trigger.TASK_COMPLETED, Trigger.TASK_STATUS_MOVEMENT, Trigger.TASK_CREATED]
  }
];

export const List = () => {
  const [selectedTab, setSelectedTab] = useState<AutomationTab>(TABS[0]);
  const [search, setSearch] = useState('');
  const companySettings = useAppSelector(selectCompanySettings);

  const isRequestsEnabled = companySettings?.features?.requests;

  const { data: allAutomations } = useAutomationList();

  const navigate = useNavigate();

  const tabs = useMemo(() => {
    return TABS.filter((tab) => {
      if (tab.recordType === RecordType.DEAL && !isRequestsEnabled) {
        return false;
      }

      return true;
    });
  }, [isRequestsEnabled]);

  const filteredRows = useMemo(() => {
    if (!allAutomations) {
      return [];
    }

    return allAutomations
      .filter((automation) => {
        if (selectedTab.id === 'all') {
          return true;
        }

        const trigger = automation.steps.find((step) => step.type === AutomationStepType.TRIGGER);

        if (!trigger) {
          return false;
        }

        const allowedTrigger = selectedTab.triggers?.includes(trigger.key as Trigger);

        if (!allowedTrigger) {
          return false;
        }

        if (trigger.key === Trigger.CALL_UPSERTED) {
          const recordType = trigger.fields.find((field) => field.key === 'recordType')?.value;

          return !recordType || recordType === selectedTab.recordType;
        }

        return true;
      })
      .filter((automation) => {
        if (!search) {
          return true;
        }

        return automation.name.toLowerCase().includes(search.toLowerCase());
      });
  }, [search, allAutomations, selectedTab]);

  const handleNewClick = useCallback(() => {
    navigate('./new');
  }, [navigate]);

  return (
    <Container>
      <PageHeader>
        <Title>Automations</Title>
      </PageHeader>
      <Header>
        <HeaderLeft>
          <Button onClick={handleNewClick} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Automation
          </Button>

          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
        <Tabs tabs={tabs} selected={selectedTab.id} onChange={setSelectedTab} />
      </Header>

      <TableContainer>
        <Table>
          <thead>
            <th>Name</th>
            <th>Trigger</th>
            <th>Action</th>
            <th>Active</th>
            <th>Created by</th>
            <th />
          </thead>
          <tbody>
            {filteredRows.map((automation) => (
              <AutomationRow key={automation.id} automation={automation} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
