import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { useCompanyNotifications } from '@hooks/workspace/notifications/useCompanyNotifications';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { isCustomerPortalEnabled, selectCompanySettings } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { EntityType } from '@generated/types/graphql';
import { Entity } from './Entity';
import { Container, Header, Description, Title, Content, SideNav, List, SideNavItem } from './styled';

const makeTitleHref = (title: string) => {
  return title.toLowerCase().replace(' ', '-');
};

export const Notifications = () => {
  const { hash } = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(hash ? hash.slice(1) : 'communication');
  const observer = useRef<IntersectionObserver>(null);

  const companySettings = useAppSelector(selectCompanySettings);

  const isRequestsEnabled = companySettings?.features?.requests;
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const isJustClicked = useRef(false);
  const clickTimeout = useRef<NodeJS.Timeout>(null);

  const { data: notificationTypesUnfiltered = [] } = useCompanyNotifications();

  const notificationTypes = useMemo(() => {
    return notificationTypesUnfiltered.filter((entity) => {
      if (entity.entityType === EntityType.Request) {
        return isRequestsEnabled;
      }

      if (entity.entityType === EntityType.Action) {
        return isPortalEnabled;
      }

      return true;
    });
  }, [notificationTypesUnfiltered, isRequestsEnabled, isPortalEnabled]);

  useEffect(() => {
    if (notificationTypes.length === 0) {
      return () => {};
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
        if (visibleSection && !isJustClicked.current) {
          setActiveItem(visibleSection.id);
        }
      },
      { threshold: 0.5 }
    );

    const sections = document.querySelectorAll('[data-section]');

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, [notificationTypes]);

  useEffectOnceBy(
    () => {
      if (notificationTypes.length === 0) {
        return;
      }

      if (activeItem === 'communication') {
        // dont scroll to the active section if it's the first one
        return;
      }

      // scroll to the active section
      const activeSection = document.getElementById(activeItem);

      if (activeSection) {
        activeSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    () => notificationTypes.length > 0,
    [notificationTypes, activeItem]
  );

  const handleNavItemClick = useCallback((e) => {
    isJustClicked.current = true;

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }

    setActiveItem(e.target.href.split('#')[1]);

    clickTimeout.current = setTimeout(() => {
      isJustClicked.current = false;
    }, 500);
  }, []);

  return (
    <Container>
      <Header>
        <Title>Notifications</Title>
        <Description>
          Send notifications to your employees about project status changes, scheduled visits, assigned work orders, and
          more.
        </Description>
      </Header>

      <Content>
        <List>
          {notificationTypes.map((entity) => (
            <Entity key={entity.title} entity={entity} />
          ))}
        </List>
        <SideNav>
          {notificationTypes.map((entity) => (
            <SideNavItem
              onClick={handleNavItemClick}
              isActive={makeTitleHref(entity.title) === activeItem}
              key={entity.title}
              href={`#${makeTitleHref(entity.title)}`}
            >
              {entity.title}
            </SideNavItem>
          ))}
        </SideNav>
      </Content>
    </Container>
  );
};
