import React, { useMemo } from 'react';
import { usePortalNotifications } from '@hooks/workspace/notifications/usePortalNotifications';
import { EntityType } from '@generated/types/graphql';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { Title, Description } from '../styled';
import { makeTitleHref } from '../helpers';
import { List } from './styled';
import { Entity } from './Entity';

export const Notifications = () => {
  const { data: notificationTypesUnfiltered = [] } = usePortalNotifications();

  const companySettings = useAppSelector(selectCompanySettings);

  const isRequestsEnabled = companySettings?.features?.requests;

  const notificationTypes = useMemo(() => {
    return notificationTypesUnfiltered.filter((entity) => {
      if (entity.entityType === EntityType.Request) {
        return isRequestsEnabled;
      }

      return true;
    });
  }, [notificationTypesUnfiltered, isRequestsEnabled]);

  return (
    <div data-section id={makeTitleHref('Notifications')}>
      <Title>Notifications</Title>
      <Description>
        Send notifications to your clients about project status changes, scheduled visits, request statuses, requested
        documents, and more.
      </Description>

      <List>
        {notificationTypes.map((entity) => (
          <Entity key={entity.title} entity={entity} />
        ))}
      </List>
    </div>
  );
};
