import moment from 'moment';

const formatTime = (date: moment.Moment) => {
  return date.format('h:mma').replace(':00', '');
};

const formatDateTime = (date: moment.Moment, isAllDay: boolean) => {
  if (isAllDay) {
    return 'All day';
  }

  return `${formatTime(date)}`;
};

export const formatWorkOrderEventDateTime = (order: any) => {
  if (!order.startDate && !order.endDate) {
    return '';
  }

  if (order.startDate && order.endDate) {
    const start = moment(order.startDate);
    const end = moment(order.endDate);

    if (start.isSame(end, 'date')) {
      if (order.startDateAllDay && order.endDateAllDay) {
        return 'All day';
      } else {
        return `${formatTime(start)} - ${formatTime(end)}`;
      }
    } else {
      return `${formatDateTime(start, order.startDateAllDay)} - ${formatDateTime(end, order.endDateAllDay)}`;
    }
  }

  if (order.startDate) {
    return formatDateTime(moment(order.startDate), order.startDateAllDay);
  }

  return formatDateTime(moment(order.endDate), order.endDateAllDay);
};
