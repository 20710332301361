import React from 'react';

export const ZendeskIcon = ({ size = 24 }: { size?: string | number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2381 8.69694V18.9991H2L11.2381 8.69694ZM11.2381 5C11.2381 6.13156 10.7514 7.21677 9.88518 8.01691C9.01895 8.81703 7.84408 9.26655 6.61904 9.26655C5.39399 9.26655 4.21913 8.81703 3.35288 8.01691C2.48665 7.21677 2 6.13156 2 5H11.2381ZM12.76 19C12.76 17.8685 13.2466 16.7832 14.1129 15.9831C14.9791 15.1829 16.154 14.7335 17.379 14.7335C18.604 14.7335 19.7789 15.1829 20.6451 15.9831C21.5114 16.7832 21.998 17.8685 21.998 19H12.76ZM12.76 15.3031V5H22L12.76 15.3021V15.3031Z"
      fill="#03363D"
    />
  </svg>
);
