import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Required } from '@common/Formik/Form/styled';
import { Modal } from '@components/common/ModalMui';
import { InputLabel, TextField } from '@common/ui';
import { CatalogItemCreateDto, CatalogItemFromMasterDto, CatalogItemUpdateDto } from '@types';
import { UiStates } from '@enums';
import { isMasterItem, isProduct, useCatalogItemsMutation, useMuiClasses } from '@hooks';
import { CatalogItem } from '@generated/types/graphql';
import { RichEditorReactForm } from '@common';
import BoxRow from '../../../common/Formik/Form/BoxRow';
import { Button, ButtonVariant } from '@kit/ui/Button';

type CatalogItemFormValues = CatalogItemCreateDto;

const GenericTextField = <T extends CatalogItemFormValues, K extends [keyof T]>({
  label,
  fieldName,
  required,
  type = 'text',
  disabled = false
}: {
  label: string;
  fieldName: string;
  required?: boolean;
  type?: 'text' | 'number';
  disabled?: boolean;
}) => {
  const { textField } = useMuiClasses();

  return (
    <div>
      <InputLabel>{required ? <Required>{label}</Required> : label}</InputLabel>
      <Controller<T, K>
        name={fieldName}
        {...(required
          ? {
              rules: { required }
            }
          : {})}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField
              {...field}
              required={required}
              classes={textField}
              type={type}
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              helperState={error ? UiStates.ERROR : UiStates.DEFAULT}
              disabled={disabled}
            />
          );
        }}
      />
    </div>
  );
};

type CatalogItemModalProps = {
  entity: CatalogItem | null;
  onClose: () => unknown;
};

export const CatalogItemModal: React.FC<CatalogItemModalProps> = ({ entity, onClose }) => {
  const isLink = isMasterItem(entity);
  const isUpdate = !isLink && !!entity?.id;
  const isProductEntity = isProduct(entity?.category);
  const { update, create, createFromMaster } = useCatalogItemsMutation();
  const form = useForm<CatalogItemFormValues>({
    defaultValues: useMemo(() => entity, [entity])
  });

  useEffect(() => {
    form.reset(entity);
  }, [entity]);

  const handleSubmit = async (values: CatalogItemFormValues) => {
    const dto = {
      ...values,
      categoryId: entity?.category?.id,
      isCustom: true,
      cost: +values.cost,
      price: +values.price,
      type: entity?.category?.parentCode
    };

    if (isUpdate) {
      await update.mutate({
        id: entity.id,
        dto: dto as unknown as CatalogItemUpdateDto
      });
    } else if (isLink) {
      await createFromMaster.mutate({
        dto: {
          cost: dto.cost,
          price: dto.price,
          ...(dto.description ? { description: dto.description } : {}),
          masterItemId: entity.id,
          ...(dto.imageId ? { imageId: dto.imageId } : {}),
          ...(Object.keys(dto.spec ?? {}).length > 0 ? { spec: dto.spec } : {})
        } as unknown as CatalogItemFromMasterDto
      });
    } else {
      await create.mutate({ dto: dto as unknown as CatalogItemCreateDto });
    }

    onClose();
  };

  return (
    <Modal
      open={typeof entity !== 'undefined'}
      onClose={onClose}
      title={`${isLink ? 'Enable' : isUpdate ? 'Update' : 'Create'} ${
        !isLink && !isUpdate ? entity?.category?.value : entity?.name
      }`}
      footerButtons={[
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>,
        <Button variant={ButtonVariant.Primary} key="submit_modal"  onClick={form.handleSubmit(handleSubmit)}>
          {isUpdate ? 'Update' : 'Save'}
        </Button>
      ]}
    >
      <FormProvider {...form}>
        <BoxRow half={isProductEntity}>
          <GenericTextField<CatalogItemFormValues, 'name'>
            label="Name"
            fieldName="name"
            required
            disabled={isMasterItem(entity)}
          />
          {isProductEntity && (
            <GenericTextField<CatalogItemFormValues, 'manufacturer'>
              label="Manufacturer"
              fieldName="manufacturer"
              disabled={isMasterItem(entity)}
              required={isProductEntity}
            />
          )}
        </BoxRow>
        <BoxRow>
          <div>
            <RichEditorReactForm label="Description" name="description" />
          </div>
        </BoxRow>
        <BoxRow half={isProductEntity}>
          {isProductEntity && (
            <GenericTextField<CatalogItemFormValues, 'sku'>
              label="SKU"
              fieldName="sku"
              disabled={isMasterItem(entity)}
            />
          )}
          <GenericTextField<CatalogItemFormValues, 'code'>
            label="Code"
            fieldName="code"
            disabled={isMasterItem(entity)}
          />
        </BoxRow>
        {/*{isProductEntity && (*/}
        {/*  <Grid item md={6} xs={12}>*/}
        {/*    <GenericTextField<CatalogItemFormValues, 'datasheet'>*/}
        {/*      label="Datasheet"*/}
        {/*      fieldName="datasheet"*/}
        {/*      disabled={isMasterItem(entity)}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
        <BoxRow half>
          <GenericTextField<CatalogItemFormValues, 'cost'> label="Cost" fieldName="cost" type="number" required />
          <GenericTextField<CatalogItemFormValues, 'price'> label="Price" fieldName="price" type="number" required />
        </BoxRow>
      </FormProvider>
    </Modal>
  );
};
