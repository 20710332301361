import React from 'react';

export const PipedriveIcon = ({ size = 24 }: { size?: string | number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.50024 9.55137C9.50024 11.4451 10.4609 13.4879 12.5749 13.4879C14.1427 13.4879 15.7279 12.2639 15.7279 9.52358C15.7279 7.12101 14.4818 5.50808 12.6271 5.50808C11.1158 5.50808 9.50024 6.56971 9.50024 9.55137ZM13.3719 2C17.1623 2 19.7111 5.00212 19.7111 9.47094C19.7111 13.8696 17.0261 16.9404 13.1878 16.9404C11.3578 16.9404 10.1856 16.1566 9.57269 15.5892C9.57704 15.7238 9.57993 15.8744 9.57993 16.0352V22H5.65326V6.10763C5.65326 5.87658 5.57936 5.80347 5.35043 5.80347H4V2.32317H7.29493C8.81199 2.32317 9.20031 3.09527 9.2742 3.69043C9.89002 3.00022 11.1665 2 13.3719 2Z"
      fill="#26292C"
    />
  </svg>
);
