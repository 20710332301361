import React from 'react';

export const QuickbooksIcon = ({ size = 24 }: { size?: string | number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24C18.6259 24 24 18.6259 24 12C24 5.37407 18.6259 0 12 0C5.37407 0 0 5.37407 0 12C0 18.6259 5.37017 24 12 24Z"
      fill="#2CA01C"
    />
    <path
      d="M7.99875 7.33183C5.42091 7.33183 3.33057 9.42218 3.33057 12C3.33057 14.5778 5.41701 16.6643 7.99875 16.6643H8.66563V14.9327H7.99875C6.38029 14.9327 5.06602 13.6185 5.06602 12C5.06602 10.3816 6.38029 9.06729 7.99875 9.06729H9.60161V18.1346C9.60161 19.09 10.3777 19.8661 11.3332 19.8661V7.33183H7.99875ZM16.0013 16.6643C18.5792 16.6643 20.6695 14.5739 20.6695 12C20.6695 9.42608 18.5831 7.33573 16.0013 7.33573H15.3345V9.06729H16.0013C17.6198 9.06729 18.9341 10.3816 18.9341 12C18.9341 13.6185 17.6198 14.9327 16.0013 14.9327H14.3985V5.86547C14.3985 4.90999 13.6224 4.13391 12.6669 4.13391V16.6643H16.0013Z"
      fill="white"
    />
  </svg>
);
