import { projectAdapterNew } from '@adapters/ProjectAdapters/ProjectAdapter';
import { Project } from '@generated/types/graphql';
import { RecordIdsGroup } from '@hooks/useRecords';
import { postGraphql } from '@services/api/base/graphql';
import { ProjectAdapted } from '@types';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';

const fetchRecords = async (ids: number[]) => {
  try {
    if (ids.length === 0) {
      return [];
    }

    const data = await postGraphql<{ projects: Project[] }>(
      gql`
        query REQUESTS_BY_IDS_QUERY($ids: [Int!]!) {
          projects(filter: { id: { in: $ids } }) {
            id
            uid
            title
            description
            address
            city
            street
            zipcode
            state
            geoLocation
            createdAt
            progress
            homeOwnerEmail
            imageUrl
            isActive
            status
            isCompleted
            companyId
            ahj
            phone
            updatedAt
            streetViewUrl
            type
            dealValue
            dealSize
            projectValue
            projectSize
            accountType
            confidence
            primaryEmail
            primaryPhone
            lastActivity
            overdueBy
            trades
            lastInboundCallDisposition
            lastInboundCallTime
            lastInboundEmailTime
            lastInboundSmsTime
            lastOutboundCallDisposition
            lastOutboundCallTime
            lastOutboundEmailTime
            lastOutboundSmsTime
            totalInboundCallsCount
            totalOutboundCallsCount
            createdByUser {
              id
              firstName
              lastName
              email
              avatarUrl
            }
            referrerContact {
              id
              name
              portalStatus
            }
            blueprint {
              id
              name
            }
            stage {
              id
              name
              type
            }
            owner {
              id
              firstName
              lastName
              email
              avatarUrl
            }
            salesRep {
              id
              firstName
              lastName
              email
              avatarUrl
            }
            projectManager {
              id
              firstName
              lastName
              email
              avatarUrl
            }
            jurisdiction {
              id
              uuid
              name
            }
            projectMembers {
              member {
                id
                firstName
                lastName
                email
                avatarUrl
              }
            }
            projectPropertiesValues(
              filter: {
                or: [{ textValue: { isNull: false, notEqualTo: "" } }, { timeRangeDaysValue: { isNull: false } }]
              }
            ) {
              columnId
              dateValueAllDay
              textValue
              numericValue
              dateValue
              workerValue
              dropdownValue
              timeRangeDaysValue
              userByWorkerValue {
                id
                firstName
                lastName
                email
                avatarUrl
              }
              column {
                type
                multiple
                mappedName
              }
              files {
                id
                name
                type
                metaData
                downloadUrl
                projectId
              }
            }
            projectStageUpdates(orderBy: CREATED_AT_DESC, first: 1) {
              id
              stage {
                name
                id
                redSla
                yellowSla
              }
              createdAt
            }
            parentProjectId
            requestStatus

            pinnedComments: feedsByProjectIdConnection(
              filter: { eventType: { equalTo: "COMMENTED" }, relatedComment: { isPinned: { equalTo: true } } }
            ) {
              nodes {
                id
                createdAt
                virtualCreatedAt
                createdBy: createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                  phone
                }
                parentId
                event
                eventType
                id
                payload
                relatedComment {
                  id
                  scope
                  comment
                  createdAt
                  updatedAt
                  createdByType
                  type
                  isPinned
                  pinnedAt
                  privilege

                  file {
                    metaData
                    annotations
                  }

                  files {
                    id
                    name
                    metaData
                    downloadUrl
                    type
                    projectId
                  }
                }
              }
            }
          }
        }
      `,
      { ids }
    );

    return data.projects.map(projectAdapterNew);
  } catch (e) {
    throw apiErrorHandler(`Error fetching clients`, e);
  }
};

const useInitialRecords = (ids: number[], isEnabled: boolean = true) => {
  return useQuery([ReactQueryKey.RequestsListInitialGroupData, ids], () => fetchRecords(ids), { enabled: isEnabled });
};

const sortProjectsByIds = (projects: ProjectAdapted[], ids: number[]) => {
  return [...projects].sort((a, b) => {
    const aIndex = ids.indexOf(a.projectId);
    const bIndex = ids.indexOf(b.projectId);

    if (aIndex === -1 || bIndex === -1) {
      return 0;
    }

    return aIndex - bIndex;
  });
};

export const useInitialGroupedRecords = (
  groups?: RecordIdsGroup[],
  perPage?: number = 10,
  isEnabled: boolean = true
) => {
  const { data, isLoading } = useInitialRecords(
    groups.flatMap((group) => group.ids.slice(0, perPage)),
    isEnabled
  );

  const groupsWithRecords = useMemo(() => {
    return groups.map((group) => ({
      ...group,
      records: sortProjectsByIds(
        data?.filter((record) => group.ids.slice(0, perPage).includes(record.projectId)) || [],
        group.ids.slice(0, perPage)
      )
    }));
  }, [data, groups, perPage]);

  return {
    groups: groupsWithRecords,
    isLoading
  };
};

type PaginatedProjects = {
  projects: ProjectAdapted[];
  pageParam: number;
};

export const usePagedRecords = (ids: number[], firstPageData?: ProjectAdapted[], perPage: number = 10) => {
  return useInfiniteQuery<PaginatedProjects>(
    [ReactQueryKey.RequestsByIds, firstPageData, ids, perPage],
    async ({ pageParam = 0 }) => {
      if (pageParam === 0 && firstPageData) {
        return {
          projects: firstPageData,
          pageParam: 0
        };
      }

      const idsToLoad = ids.slice(pageParam * perPage, pageParam * perPage + perPage);

      const projects = await fetchRecords(idsToLoad);

      return {
        projects: sortProjectsByIds(projects, idsToLoad),
        pageParam
      };
    },
    {
      // initialData: firstPageData ? { pages: [{ projects: firstPageData, pageParam: 0 }], pageParams: [undefined] } : undefined,
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage) => lastPage.pageParam + 1,
      keepPreviousData: true
    }
  );
};
